import React from 'react';
import LayoutInterface from '../../../interfaces/LayoutInterface';
import Footer from '../footer';
import Header from '../header';

import styles from './Layout.module.css';

const Layout: React.FunctionComponent<LayoutInterface> = ({ children }: LayoutInterface) => (
  <div className={styles.layoutContainer}>
    <Header />
    {children}
    <Footer />
  </div>
);

export default Layout;
