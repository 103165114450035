import React from 'react';
import { homeSectionIds } from '../../../utils/homeSectionIds';

import styles from './OurServicesSection.module.css';

const OurServicesSection: React.FunctionComponent = () => (
  <section
    id={homeSectionIds.ourServices}
    className={styles.ourServicesSectionContainer}
  >
    Our Services Section
  </section>
);

export default OurServicesSection;
