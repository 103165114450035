import React from 'react';

import styles from './Header.module.css';

const Header: React.FunctionComponent = () => (
  <header
    className={styles.headerContainer}
  >
    Header
  </header>
);

export default Header;
