import React from 'react';
import ParticlesWrapper from './particles-wrapper';
import Company from './company';
import ScrollDown from './scroll-down';
import GradientOverlay from './gradient-overlay';
import { homeSectionIds } from '../../../utils/homeSectionIds';

import styles from './HomeSection.module.css';

const HomeSection: React.FunctionComponent = () => (
  <section
    id={homeSectionIds.home}
    className={styles.homeSectionContainer}
  >
    <ParticlesWrapper />
    <GradientOverlay />
    <Company />
    <ScrollDown />
  </section>
);

export default HomeSection;
