import React, { useEffect } from 'react';
import { pages } from '../../utils/pages';
import HomeSection from './home-section';
import AboutUsSection from './about-us-section';
import BlogSection from './blog-section';
import CoFoundersSection from './co-founders-section';
import OurServicesSection from './our-services-section';
import ContactSection from './contact-section';

import styles from './Home.module.css';

const Home: React.FunctionComponent = () => {
  useEffect(() => {
    document.title = pages.HOME.title;
  }, []);

  return (
    <div className={styles.homeContainer}>
      <HomeSection />
      <AboutUsSection />
      <BlogSection />
      <OurServicesSection />
      <CoFoundersSection />
      <ContactSection />
    </div>
  );
};

export default Home;
