import React from 'react';
import { homeSectionIds } from '../../../utils/homeSectionIds';

import styles from './BlogSection.module.css';

const BlogSection: React.FunctionComponent = () => (
  <section
    id={homeSectionIds.blog}
    className={styles.blogSectionContainer}
  >
    Blog Section
  </section>
);

export default BlogSection;
