const pages = {
  HOME: {
    title: 'Insight Delta - Home',
    path: '/',
  },
  BLOG: {
    title: 'Insight Delta - Blog',
    path: '/blog',
  },
};

export { pages };
