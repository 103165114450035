import React from 'react';
import styles from './ScrollDown.module.css';

const ScrollDown: React.FunctionComponent = () => (
  <div className={styles.scrollDownContainer}>
    <a href="#about-us"><span/></a>
  </div>
);

export default ScrollDown;
