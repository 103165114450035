const homeSectionIds = {
  home: 'home',
  aboutUs: 'about-us',
  blog: 'blog',
  ourServices: 'our-services',
  coFounders: 'co-founders',
  contact: 'contact',
};

export { homeSectionIds };
