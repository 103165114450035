import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './home';
import Blog from './blog';
import Layout from './shared/layout';
import { pages } from '../utils/pages';
import UnderConstruction from './under-construction';
import environments from '../utils/environments';

const App: React.FunctionComponent = () => {

  const isUnderConstruction = process.env.NODE_ENV === environments.PRODUCTION;
  if (isUnderConstruction) return <UnderConstruction />;

  return (
    <Router>
      <Switch>
        <Route exact path={pages.HOME.path}>
          <Layout>
            <Home />
          </Layout>
        </Route>
        <Route path={pages.BLOG.path}>
          <Layout>
            <Blog />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
