import React from 'react';
import UnderConstructionImage from '../../assets/images/under-construction.svg';

import styles from './UnderConstruction.module.css';

const UnderConstruction: React.FunctionComponent = () => (
  <div className={styles.underConstructionContainer}>
    <img
      className={styles.underConstruction}
      src={UnderConstructionImage}
    />
  </div>
);

export default UnderConstruction;
