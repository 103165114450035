import React from 'react';
import { homeSectionIds } from '../../../utils/homeSectionIds';

import styles from './CoFoundersSection.module.css';

const CoFoundersSection: React.FunctionComponent = () => (
  <section
    id={homeSectionIds.coFounders}
    className={styles.coFoundersSectionContainer}
  >
    Co-Founders Section
  </section>
);

export default CoFoundersSection;
