import React from 'react';
import { homeSectionIds } from '../../../utils/homeSectionIds';

import styles from './AboutUsSection.module.css';

const AboutUsSection: React.FunctionComponent = () => (
  <section
    id={homeSectionIds.aboutUs}
    className={styles.aboutUsSectionContainer}
  >
    About Us Section
  </section>
);

export default AboutUsSection;
