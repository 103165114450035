import React from 'react';
import Typed from 'react-typed';
import companyServices from '../../../../utils/companyServices';

import styles from './Company.module.css';

const Company: React.FunctionComponent = () => (
  <div className={styles.companyContainer}>
    <h1 className={styles.name}>Insight Delta</h1>
    <br/>
    <p className={styles.services}>
      <Typed
        strings={companyServices}
        typeSpeed={40}
        backSpeed={50}
        loop
      />
    </p>
  </div>
);

export default Company;
