import React from 'react';

import styles from './Footer.module.css';

const Footer: React.FunctionComponent = () => {
  const currentYear = (new Date()).getFullYear();
  return (
    <div
      className={styles.footerContainer}
    >
      <p className={styles.copyright}>
        © Copyright {currentYear}
      </p>
    </div>
  );
};

export default Footer;
