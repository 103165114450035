import React, { useEffect } from 'react';
import { pages } from '../../utils/pages';

import styles from './Blog.module.css';

const Blog: React.FunctionComponent = () => {
  useEffect(() => {
    document.title = pages.BLOG.title;
  }, []);

  return (
    <div className={styles.blogContainer}>
      Blog
    </div>
  );
};

export default Blog;
